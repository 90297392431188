/* TailwindCss */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.topic {
		@apply mb-4 font-semibold leading-tight text-left text-3xl lg:text-3xl;
	}
	.sub-title-b-b {
		@apply mb-4 text-2xl text-black font-medium border-b pb-2;
	}
	.paragraph {
		@apply mb-4 text-base text-gray-600 font-light;
	}
	.docs-section {
		@apply flex flex-col justify-start pt-10 mb-10;
	}
	.code-title {
		@apply text-sm text-black font-semibold;
	}
}
